@import url('https://fonts.googleapis.com/css?family=Roboto:400,700,900');

/* RESETS
============================================ */

html {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
*, *:before, *:after {
  -webkit-box-sizing: inherit;
          box-sizing: inherit;
}

body {
  margin: 20px 0;
  padding: 0;
  line-height: 1;
  font-family: 'Roboto', sans-serif;
  color: #202020;
  background-color: #202020;
  font-smooth: always;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.card .badge {
  font-size: 16px
}

.card .text-secondary {
  line-height: 25px;
}

.toast.show {
  position: absolute;
  left: 20px;
  bottom: 20px;
  z-index: 10;
}